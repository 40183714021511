<template>
  <el-card>
    <el-row :gutter="20" class="header">
<!--      <el-switch-->
<!--        v-model="value2"-->
<!--        active-color="#13ce66"-->
<!--        inactive-color="#ff4949">-->
<!--      </el-switch>-->
      <el-col :span="7">
        <el-input placeholder="请输入转诊单名称..." clearable v-model="queryForm.query"></el-input>
      </el-col>
      <el-button type="primary" :icon="Search" @click="initProductList">搜索</el-button>
<!--      <el-button type="primary"  @click="exportExcel">导出excel</el-button>-->

      <el-button type="primary"  @click="exportAllExcel">全量导出excel</el-button>

      <el-button type="primary"  @click="openMonthDialog">按月导出excel</el-button>

      <el-button type="primary"  @click="openMonthSiftDialog">多条件筛选</el-button>

      <el-button type="primary"  @click="openStatisticsReportsDialog">统计报表</el-button>

<!--      <el-button type="primary"  @click="openTownshipSiftDialog">按乡镇筛选</el-button>-->

<!--      <el-switch-->
<!--        style="display: flex;margin-left:50px"-->
<!--        v-model="queryForm.remark"-->
<!--        active-color="#13ce66"-->
<!--        inactive-color="#ff4949"-->
<!--        active-text="已审核"-->
<!--        inactive-text="未审核"-->
<!--        active-value="1"-->
<!--        inactive-value="0"-->
<!--        @change="remark_sift">-->
<!--      </el-switch>-->

<!--      <el-switch-->
<!--        style="display: flex;margin-left:50px"-->
<!--        v-model="queryForm.status"-->
<!--        active-color="#ff4949"-->
<!--        inactive-color="#13ce66"-->
<!--        active-text="无效"-->
<!--        inactive-text="有效"-->
<!--        active-value="1"-->
<!--        inactive-value="0"-->
<!--        @change="status_sift">-->
<!--      </el-switch>-->

      <!--  选择月份导出表格对话框     -->
      <el-dialog title="选择日期" width="25%" center   v-model:visible="month_dialog_Visible" :model-value="month_dialog_Visible" @close="month_dialog_Visible = false">
        <el-config-provider :locale="locale">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            >
          </el-date-picker>
<!--          <div class="demonstration">值：{{ dateRange }}</div>-->
        </el-config-provider>

        <template v-slot:footer class="dialog-footer">
          <el-button @click="month_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="export1">确 定</el-button>
        </template>
      </el-dialog>

      <!--  筛选对话框     -->
      <el-dialog title="多条件筛选" width="35%" center   v-model:visible="month_sift_dialog_Visible" :model-value="month_sift_dialog_Visible" @close="month_sift_dialog_Visible = false">
        <!--  按日期筛选     -->
        <el-row :gutter="20"  class="header" >
          <div style="width: 200px;display: flex;justify-content: center;align-items: center;margin-right: 20px">选择日期:</div>
          <el-config-provider :locale="locale">
            <el-date-picker
              v-model="month_sift_dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDateRange"
            >
            </el-date-picker>
          </el-config-provider>
        </el-row>

        <!--  按乡镇筛选    -->
        <el-row :gutter="20"  class="header" >
          <div style="width: 200px;display: flex;justify-content: center;align-items: center;margin-right: 20px">选择乡镇:</div>
          <el-select v-model="queryForm.townshipName" filterable clearable  placeholder="请选择" @focus="load_township">
            <el-option
              v-for="item in range_township"
              :key="item"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-row>

        <!-- 筛选有效无效-->
        <el-row :gutter="20" class="header">
          <div style="width: 200px;display: flex;justify-content: center;align-items: center;margin-right: 20px">有效/无效:</div>
          <el-select v-model="queryForm.status" filterable placeholder="请选择" @focus="load_township" change="chooseStatus">
            <el-option
              v-for="item in range_status"
              :key="item"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-row>

        <!-- 筛选已审核未审核-->
        <el-row :gutter="20" class="header">
          <div style="width: 200px;display: flex;justify-content: center;align-items: center;margin-right: 20px">已审核/未审核:</div>
          <el-select v-model="queryForm.remark" filterable placeholder="请选择" @focus="load_township" change="chooseRemark">
            <el-option
              v-for="item in range_remark"
              :key="item"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-row>

        <template v-slot:footer class="dialog-footer">
          <el-button @click="month_sift_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="click_to_sift">确 定</el-button>
        </template>
      </el-dialog>

      <!--  按乡镇筛选对话框     -->
      <el-dialog title="选择乡镇" width="25%" center   v-model:visible="township_sift_dialog_Visible" :model-value="township_sift_dialog_Visible" @close="township_sift_dialog_Visible = false">
        <el-select v-model="queryForm.townshipName" filterable placeholder="请选择" @focus="load_township">
          <el-option
            v-for="item in range_township"
            :key="item"
            :label="item.label"
            :value="item.label">
          </el-option>
        </el-select>

        <template v-slot:footer class="dialog-footer">
          <el-button @click="township_sift_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="township_sift">确 定</el-button>
        </template>
      </el-dialog>


<!--统计报表弹窗-->
      <el-dialog title="统计报表" width="40%" center   v-model:visible="statistics_reports_dialog_Visible" :model-value="statistics_reports_dialog_Visible" @close="statistics_reports_dialog_Visible = false">
        <!--  选择日期     -->
        <el-row :gutter="20"  class="header" style="justify-content: center;align-items: center;" >
          <div style="width: 100px;display: flex;justify-content: center;align-items: center;margin: 10px">选择日期:</div>
          <el-config-provider :locale="locale" >
            <el-date-picker
              v-model="statistics_reports_dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="margin-right: 30px"
            >
            </el-date-picker>
          </el-config-provider>

<!--          <template v-slot:footer class="dialog-footer">-->
            <el-button @click="clear_statistics_reports_dateRange">清 空</el-button>
            <el-button type="primary" @click="statistics_reports_sift_by_dateRange">确 定</el-button>
<!--          </template>-->
        </el-row>

        <el-config-provider :locale="locale">
          <el-table
            :data="statisticsData"
            show-summary
            style="width: 100%">
            <el-table-column
              prop="id"
              label="乡镇id"
              width="180">
            </el-table-column>
            <el-table-column
              prop="township"
              label="乡镇"
              width="180">
            </el-table-column>
            <el-table-column
              prop="count"
              label="累计上报数量">
            </el-table-column>
            <el-table-column prop="action" label="操作" width="100" fixed="right">
              <template v-slot="scope">
                <el-button type="primary" :icon="document" @click="openTownshipStatisticsReportsDialog(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>

        </el-config-provider>

        <template v-slot:footer class="dialog-footer">
          <el-button @click="statistics_reports_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="export_simple_statistics_reports">导出简易报表</el-button>
          <el-button type="primary" @click="export_statistics_reports">导出详细报表</el-button>
        </template>
      </el-dialog>

<!--乡镇弹窗-->
      <el-dialog title="乡镇" width="50%" center   v-model:visible="township_statistics_reports_dialog_Visible" :model-value="township_statistics_reports_dialog_Visible" @close="township_statistics_reports_dialog_Visible = false">
        <!--  选择日期     -->
<!--        <el-row :gutter="20"  class="header"  style="justify-content: center;align-items: center;">-->
<!--          <div style="width: 100px;display: flex;justify-content: center;align-items: center;margin: 10px">选择日期:</div>-->
<!--          <el-config-provider :locale="locale">-->
<!--            <el-date-picker-->
<!--              v-model="township_statistics_reports_dateRange"-->
<!--              type="daterange"-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始日期"-->
<!--              end-placeholder="结束日期"-->
<!--              style="margin-right: 30px"-->
<!--            >-->
<!--            </el-date-picker>-->
<!--          </el-config-provider>-->

<!--          &lt;!&ndash;          <template v-slot:footer class="dialog-footer">&ndash;&gt;-->
<!--          <el-button @click="clear_township_statistics_reports_dateRange">清 空</el-button>-->
<!--          <el-button type="primary" @click="township_statistics_reports_sift_by_dateRange">确 定</el-button>-->
<!--          &lt;!&ndash;          </template>&ndash;&gt;-->
<!--        </el-row>-->

        <el-config-provider :locale="locale">
          <el-table
            :data="townshipStatisticsData"
            show-summary
            style="width: 100%">
            <el-table-column
              prop="doctorName"
              label="乡镇医生姓名"
              width="180">
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系电话"
              width="180">
            </el-table-column>
            <el-table-column
              prop="township"
              label="乡镇"
              width="180">
            </el-table-column>
            <el-table-column
              prop="village"
              label="村"
              width="180">
            </el-table-column>
            <el-table-column
              prop="count"
              label="累计上报数量">
            </el-table-column>
          </el-table>

        </el-config-provider>

        <template v-slot:footer class="dialog-footer">
          <el-button @click="township_statistics_reports_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="township_statistics_reports_dialog_Visible = false">确 定</el-button>
        </template>
      </el-dialog>



<!--      <el-button type="primary" @click="handleDialogValue()">添加转诊单</el-button>-->
    </el-row>
    <el-table :data="tableData" height="600" stripe style="width: 100%">

<!--      <el-table-column prop="num" label="编号" width="150" fixed/>-->

      <el-table-column prop="townshipName" label="乡镇" width="150" fixed/>

      <el-table-column prop="doctorName" label="乡镇上报人" width="150" />

      <el-table-column prop="superDoctorName" label="市级下派人" width="150" />

      <el-table-column prop="doctorTelephone" label="医生手机号码" width="150" />

      <el-table-column prop="createTime" label="上传时间" width="150" />

      <el-table-column prop="name" label="病人姓名" width="150" />

      <el-table-column prop="telephone" label="病人手机号码" width="150" />

      <el-table-column prop="sex" label="病人性别" width="100" />

      <el-table-column prop="idNumber" label="病人身份证号码" width="200" />

      <el-table-column prop="intent" label="转诊目的" width="100" />

      <el-table-column prop="departName" label="预约挂号科室" width="350" />
<!--      range_department[preDepartmentId-20]-->

<!--      <el-table-column prop="remark" label="是否审核" width="100" >-->
<!--        <template v-slot="scope">-->
<!--          <el-switch v-model="scope.row.remark"   ></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column prop="examine" label="是否审核" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.examine_type">{{ scope.row.examine }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="endDepartment" label="终审科室" width="350" />

      <el-table-column prop="arriveTime" label="预计到达时间" width="200" />

<!--      <el-table-column prop="integral" label="申请积分" width="100" />-->
<!--      <el-table-column prop="text_isArrive" label="是否入院" width="100" />-->
      <el-table-column prop="text_isArrive" label="是否入院" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.type_isArrive">{{ scope.row.text_isArrive }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="goodsPic" label="病人相关资料" width="150"  >
        <template v-slot="scope">
            <el-image
              style="width: 40px; height: 40px"
              :src="'https://tcm.volans.top/'+scope.row.imageUrl"
              :preview-src-list="['https://tcm.volans.top/'+scope.row.imageUrl]"
              fit="cover"
              append-to-body
            >
            </el-image>
<!--          <img :src="'http://47.106.246.14:8081'+scope.row.imageUrl" :preview-src-list="srcList" width="45" height="45"/>-->
        </template>
      </el-table-column>



<!--      <el-table-column prop="type" :formatter="typeFormatter" label="商品类别" width="200"/>-->




<!--      <el-table-column prop="swiper" label="首页幻灯？" width="100" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <el-switch v-model="row.swiper" @change="hotSwiperChangeHandle(row)"/>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column prop="swiperPic" label="幻灯图片" width="200" align="center">-->
<!--        <template v-slot="{row}">-->
<!--          <img :src="getServerUrl()+'/image/swiper/'+row.swiperPic" width="150" height="75" />-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column prop="swiperSort" label="幻灯排序" width="100" align="center"/>-->

      <el-table-column prop="patientDisease" label="病人病情描述" width="300" />

      <el-table-column prop="text_isInvalid" label="是否有效" width="100" >
        <template v-slot="scope">
          <el-tag size="default" :type="scope.row.type_isInvalid">{{ scope.row.text_isInvalid }}</el-tag>
        </template>
      </el-table-column>



      <el-table-column prop="action" label="操作" width="200" fixed="right">
        <template v-slot="scope">
<!--          <el-button type="success" @click="handleChangeImage(scope.row)">发放积分</el-button>-->
<!--          <el-button type="primary" @click="handleChangeSwiper(scope.row)">幻灯设置</el-button>-->
          <el-button type="primary" :icon="Edit" @click="handleDialogValue(scope.row)">编辑</el-button>
          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row.reportId)">删除</el-button>
<!--          <el-button type="primary" @click="handleChangeProductSwiperImage(scope.row)">轮播图片设置</el-button>-->
        </template>

      </el-table-column>

    </el-table>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>

  <Dialog v-model="dialogVisible" :dialogTitle="dialogTitle" @initProductList="initProductList"  :dialogValue="dialogValue"/>
  <ImageDialog v-model="imageDialogVisible" :id="id" @initProductList="initProductList"/>
  <SwiperDialog v-model="swiperDialogVisible" :id="id" :swiperSort="swiperSort" @initProductList="initProductList" />
  <ProductSwiperImageDialog :productId="id" v-model="productSwiperImageDialogVisible"></ProductSwiperImageDialog>
</template>

<script setup>

import {Search,Edit,Delete } from '@element-plus/icons-vue'
import { ref } from 'vue'
import axios from '@/util/axios'


import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'
import ImageDialog from './components/imageDialog'
import SwiperDialog from './components/swiperDialog'
import ProductSwiperImageDialog from './components/productSwiperImageDialog'

import {ElMessageBox,ElMessage} from 'element-plus'
import * as querystring from "querystring";

import zhCn from "element-plus/lib/locale/lang/zh-cn";
// 引入导出Excel表格依赖
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { createLogger } from "vuex";

const locale=ref(zhCn)

const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10,
  startTime:'',
  endTime:'',
  remark:'',
  status:'',
  townshipName:'',
})

const value2=ref(true)



const total=ref(0)

const id=ref(-1)

const swiperSort=ref(-1)

const tableData=ref([
  {
    num:'',
    examine:'',
    examine_type:'',
    text_isArrive:'',
    type_isArrive:'',
    text_isInvalid:'',
    type_isInvalid:'',
  }
])

const number=ref(1)

const yes_or_not=ref([

])

const range_department=ref([
  '选择预约挂号科室','急诊科（ICU）','重症医学科','外一科（普外、肛肠等方向）','外二科（泌外、男科等方向）','骨伤一科（脊柱、脑外、创伤等方向）','骨伤二科（关节、胸外、创伤等方向）','妇产科','眼耳鼻喉科','手术室','口腔科','内一科（心病方向）','内二科（肝病、脾胃、肿瘤等方向）','内三科（脑病方向）','内五科（肺病、老年病等方向）','内六科（肾病、内分泌、风湿等方向）','针灸康复科','儿科','治未病科','门诊','体检科','药剂科','医学检验科','功能科','内镜室','高压氧','放射科','CT室','供应室','120中心','新院导诊','轮科'
])

const srcList=ref([
  'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
  'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',

  'https://seopic.699pic.com/photo/50179/1691.jpg_wh1200.jpg'
  // 'http://47.106.246.14:8081'+scope.row.imageUrl
])


const dialogValue=ref({})

const dialogTitle=ref('')

const range_township=ref([
  {
    label: '',
  },
])

const range_status=ref([
  {
    label: '有效',
    value: '0',
  },
  {
    label: '无效',
    value: '1',
  },
  {
    label: '全部',
    value: '',
  },
])

const range_remark=ref([
  {
    label: '已审核',
    value: '1',
  },
  {
    label: '未审核',
    value: '0',
  },
  {
    label: '全部',
    value: '',
  },
])



const load_township=async()=>{
  // console.log('active item:', val);
  const res=await axios.get("township/findAll");
  console.log("查询所有乡镇")
  console.log(res)
  range_township.value=res.data.data.townshipList.message
  console.log("打印range_township")
  console.log(range_township.value)
  console.log(range_township.value.length)
  for( let i = 0; i < res.data.data.townshipList.message.length; i++){
    range_township.value[i].label=res.data.data.townshipList.message[i].townshipName
    console.log(range_township.value[i].label)
  }
}

  const initProductList=async()=>{
  console.log('xxx')
  const res=await axios.post("report-info/allList",queryForm.value);
    console.log(res)
    console.log('打印row')

  tableData.value=res.data.data.map.reportAllList;
    // console.log(tableData.value[5].imageUrl)
    // console.log(tableData.value[5].preDepartmentId)
    // tableData.value[5].preDepartmentId = tableData.value[5].preDepartmentId - 20
    // console.log(tableData.value[5].preDepartmentId)
  total.value=res.data.data.map.total;


    for (let i=0; i<res.data.data.map.reportAllList.length; i++){

      // if( tableData.value[i].num==null && number.value <= total.value){
      //   console.log(number)
      //   tableData.value[i].num=number.value
      //   console.log(tableData.value[i].num)
      //   number.value=number.value+1
      //   console.log(number)
      // }


      if( tableData.value[i].remark==1){
        yes_or_not[i]=true
        console.log(yes_or_not[i])
        tableData.value[i].remark=true
        console.log(tableData.value[i].remark)
        tableData.value[i].examine='已审核'
        tableData.value[i].examine_type='success'
        console.log(tableData.value[i].examine)
      }
      else{
        yes_or_not[i]=false
        console.log(yes_or_not[i])
        tableData.value[i].remark=false
        console.log(tableData.value[i].remark)
        tableData.value[i].examine='未审核'
        tableData.value[i].examine_type='danger'
        console.log(tableData.value[i].examine)
      }

      if(tableData.value[i].isArrive==1){
        tableData.value[i].text_isArrive='已入院'
        tableData.value[i].type_isArrive='success'
      }
      else{
        tableData.value[i].text_isArrive='未入院'
        tableData.value[i].type_isArrive='danger'
      }

      if(tableData.value[i].status==1){
        tableData.value[i].text_isInvalid='无效'
        tableData.value[i].type_isInvalid='danger'
      }
      else{
        tableData.value[i].text_isInvalid='有效'
        tableData.value[i].type_isInvalid='success'
      }

    }
    console.log("打印yes_or_not")
    console.log(yes_or_not)
}


const month_dialog_Visible=ref(false)

// 点击修改密码 弹出对话框
const openMonthDialog = async(row) => {
  month_dialog_Visible.value=true

}

const dateRange=ref()
const monthExcelForm=ref({
  query:'',
  pageNum:1,
  pageSize:10,
  startTime:'',
  endTime:'',
  remark:'',
  status:'',
  townshipName:'',
})


const export1=async()=>{
  console.log("日期区间：",dateRange.value)
  console.log("日期区间：",dateRange.value[0])
  console.log("日期区间：",dateRange.value[1])

  console.log(msToDate(dateRange.value[0]).withoutTime);//2021-08-15

  console.log(msToDate(dateRange.value[1]).withoutTime);//2021-08-15

  monthExcelForm.value.startTime=msToDate(dateRange.value[0]).withoutTime
  monthExcelForm.value.endTime=msToDate(dateRange.value[1]).withoutTime

  console.log("日期区间：",monthExcelForm.value)

  // 调用导出excel函数
  exportExcel()

  // 关闭对话框
  month_dialog_Visible.value = false
}





// 按月筛选
const month_sift_dialog_Visible=ref(false)
const month_sift_dateRange=ref()

const openMonthSiftDialog = async(row) => {
  month_sift_dialog_Visible.value=true
}

const changeDateRange=async()=>{
  if( month_sift_dateRange.value == null){
    queryForm.value.startTime=''
    queryForm.value.endTime=''
  }
  else{
    console.log("日期区间：",month_sift_dateRange.value)
    console.log("日期区间：",month_sift_dateRange.value[0])
    console.log("日期区间：",month_sift_dateRange.value[1])

    console.log(msToDate(month_sift_dateRange.value[0]).withoutTime);//2021-08-15

    console.log(msToDate(month_sift_dateRange.value[1]).withoutTime);//2021-08-15

    queryForm.value.startTime=msToDate(month_sift_dateRange.value[0]).withoutTime
    queryForm.value.endTime=msToDate(month_sift_dateRange.value[1]).withoutTime
  }


  console.log("日期区间：",queryForm.value)

}

const month_sift=async()=>{
  console.log("日期区间：",month_sift_dateRange.value)
  console.log("日期区间：",month_sift_dateRange.value[0])
  console.log("日期区间：",month_sift_dateRange.value[1])

  console.log(msToDate(month_sift_dateRange.value[0]).withoutTime);//2021-08-15

  console.log(msToDate(month_sift_dateRange.value[1]).withoutTime);//2021-08-15

  queryForm.value.startTime=msToDate(month_sift_dateRange.value[0]).withoutTime
  queryForm.value.endTime=msToDate(month_sift_dateRange.value[1]).withoutTime

  console.log("日期区间：",queryForm.value)

  // 调用导出excel函数
  initProductList();

  // 关闭对话框
  month_sift_dialog_Visible.value = false
}

// 按乡镇筛选
const township_sift_dialog_Visible=ref(false)

const openTownshipSiftDialog = async(row) => {
  township_sift_dialog_Visible.value=true
}

const township_sift=async()=>{
  console.log("按乡镇筛选：",queryForm.value)

  // 调用导出excel函数
  initProductList();

  // 关闭对话框
  township_sift_dialog_Visible.value = false
}

// 按是否审核筛选
const remark_sift = () => {
  console.log("是否有效：",queryForm.value.remark)
  // 调用导出excel函数
  initProductList();
}

// 按是否有效筛选
const status_sift = () => {
  console.log("是否有效：",queryForm.value.status)
  // 调用导出excel函数
  initProductList();
}

const click_to_sift = () => {
  console.log("是否有效：",queryForm.value.startTime)
  console.log("是否有效：",queryForm.value.endTime)
  console.log("是否有效：",queryForm.value.status)
  console.log("是否审核：",queryForm.value.remark)
  console.log("按乡镇筛选：",queryForm.value.townshipName)


  // 调用导出excel函数
  initProductList();
}



// 总统计报表
// 总统计报表
// 总统计报表
const statistics_reports_dialog_Visible=ref(false)
const statisticsData=ref([
  {
    id:'',
    township:'',
    count:'',
  }
])
const statistics_reports_dateRange=ref()
const baobiao_timeRange=ref({
  startTime:'',
  endTime:'',
})
const openStatisticsReportsDialog = async(row) => {
  statistics_reports_dialog_Visible.value=true

  console.log('统计报表')
  const res=await axios.get('statistics/all?startTime='+baobiao_timeRange.value.startTime+'&endTime='+baobiao_timeRange.value.endTime,);
  console.log(res)

  statisticsData.value=res.data.data.statisticsList.message;
}

// 按日期筛选报表
const statistics_reports_sift_by_dateRange=async()=>{
  console.log("日期区间：",statistics_reports_dateRange.value)
  console.log("日期区间：",statistics_reports_dateRange.value[0])
  console.log("日期区间：",statistics_reports_dateRange.value[1])

  console.log(msToDate(statistics_reports_dateRange.value[0]).withoutTime);//2021-08-15

  console.log(msToDate(statistics_reports_dateRange.value[1]).withoutTime);//2021-08-15

  baobiao_timeRange.value.startTime=msToDate(statistics_reports_dateRange.value[0]).withoutTime
  baobiao_timeRange.value.endTime=msToDate(statistics_reports_dateRange.value[1]).withoutTime

  console.log("日期区间：",baobiao_timeRange.value)

  //  开始筛选
  openStatisticsReportsDialog()


}

// 清空总报表的筛选日期
const clear_statistics_reports_dateRange=async()=>{
  statistics_reports_dateRange.value[0]=''
  statistics_reports_dateRange.value[1]=''
  //
  // baobiao_timeRange.value.startTime=msToDate(statistics_reports_dateRange.value[0]).withoutTime
  // baobiao_timeRange.value.endTime=msToDate(statistics_reports_dateRange.value[1]).withoutTime

  baobiao_timeRange.value.startTime=''
  baobiao_timeRange.value.endTime=''

  console.log("日期区间：",baobiao_timeRange.value)

  //  开始筛选
  openStatisticsReportsDialog()
}



// 乡镇统计报表
// 乡镇统计报表
// 乡镇统计报表
const township_statistics_reports_dialog_Visible=ref(false)
const townshipStatisticsData=ref([
  {
    doctorName:'',
    phone:'',
    township:'',
    village:'',
    count:'',
  }
])
const township_statistics_reports_dateRange=ref()
const baobiao_township_timeRange=ref({
  startTime:'',
  endTime:'',
})
const selected_township=ref()
const openTownshipStatisticsReportsDialog = async(row) => {
  township_statistics_reports_dialog_Visible.value=true

  console.log('乡镇统计报表',row)
  selected_township.value=row.township

  // getTownshipStatisticsReports()


  // const res=await axios.get('statistics/select/?township='+row.township+'&startTime='+baobiao_township_timeRange.value.startTime+'&endTime='+baobiao_township_timeRange.value.endTime,);
  // console.log(res)

  const res=await axios.get('statistics/select/?township='+row.township+'&startTime='+baobiao_timeRange.value.startTime+'&endTime='+baobiao_timeRange.value.endTime,);
  console.log(res)


  townshipStatisticsData.value=res.data.data.statisticsList.message;
}
const getTownshipStatisticsReports = async() => {
  const res=await axios.get('statistics/select/?township='+selected_township.value+'&startTime='+baobiao_township_timeRange.value.startTime+'&endTime='+baobiao_township_timeRange.value.endTime,);
  console.log(res)

  townshipStatisticsData.value=res.data.data.statisticsList.message;
}

// 按日期筛选乡镇报表
const township_statistics_reports_sift_by_dateRange=async()=>{
  console.log("日期区间：",township_statistics_reports_dateRange.value)
  console.log("日期区间：",township_statistics_reports_dateRange.value[0])
  console.log("日期区间：",township_statistics_reports_dateRange.value[1])

  console.log(msToDate(township_statistics_reports_dateRange.value[0]).withoutTime);//2021-08-15

  console.log(msToDate(township_statistics_reports_dateRange.value[1]).withoutTime);//2021-08-15

  baobiao_township_timeRange.value.startTime=msToDate(township_statistics_reports_dateRange.value[0]).withoutTime
  baobiao_township_timeRange.value.endTime=msToDate(township_statistics_reports_dateRange.value[1]).withoutTime

  console.log("日期区间：",baobiao_township_timeRange.value)

  //  开始筛选
  getTownshipStatisticsReports()


}

// 清空乡镇报表的筛选日期
const clear_township_statistics_reports_dateRange=async()=>{
  township_statistics_reports_dateRange.value[0]=''
  township_statistics_reports_dateRange.value[1]=''
  //
  // baobiao_timeRange.value.startTime=msToDate(statistics_reports_dateRange.value[0]).withoutTime
  // baobiao_timeRange.value.endTime=msToDate(statistics_reports_dateRange.value[1]).withoutTime

  baobiao_township_timeRange.value.startTime=''
  baobiao_township_timeRange.value.endTime=''

  console.log("日期区间：",baobiao_township_timeRange.value)

  //  开始筛选
  getTownshipStatisticsReports()
}



// 按时间导出简易统计报表
const export_simple_statistics_reports=async()=>{

  // axios.get1('/statistics/ExcelDownload',  {
  axios.get1('/statistics/ExcelDownloadAll?startTime='+baobiao_timeRange.value.startTime+'&endTime='+baobiao_timeRange.value.endTime,  {

    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
    },
    responseType: 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
  }).
  then(function (response) {
    const blob = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"});
    const fileName = '简易统计报表.xls';
    const linkNode = document.createElement('a');

    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击

    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);

  }).catch(function (error) {
    console.log(error);
  });
}



// 按时间导出详细统计报表
const export_statistics_reports=async()=>{

  // axios.get1('/statistics/ExcelDownload',  {
  axios.get1('/statistics/ExcelDownload?startTime='+baobiao_timeRange.value.startTime+'&endTime='+baobiao_timeRange.value.endTime,  {

    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
    },
    responseType: 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
  }).
  then(function (response) {
    const blob = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"});
    const fileName = '详细统计报表.xls';
    const linkNode = document.createElement('a');

    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击

    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);

  }).catch(function (error) {
    console.log(error);
  });
}








//中国标准时间转日期
function msToDate (msec) {
  let datetime = new Date(msec);
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date) +
    ' ' +
    ((hour + 1) < 10 ? '0' + hour : hour) +
    ':' +
    ((minute + 1) < 10 ? '0' + minute : minute) +
    ':' +
    ((second + 1) < 10 ? '0' + second : second);

  let result2 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date);

  let result = {
    hasTime: result1,
    withoutTime: result2
  };

  return result;
}


// 全量导出excel
const exportAllExcel=async()=>{

  axios.get1('/report-info/ExcelDownload',  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
    },
    responseType: 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
  }).
  then(function (response) {
    const blob = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"});
    const fileName = '转诊单详情.xls';
    const linkNode = document.createElement('a');

    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击

    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);

  }).catch(function (error) {
    console.log(error);
  });
}


// 导出表格
const exportExcel=async()=>{

  // var axiosOption={
  //   method:'get',
  //   url:  '/report-info/ExcelDownload',
  //   data: data,
  //   timeout: 0,
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
  //   },
  // }
  // if(data.responseType=='blob'){
  //   axiosOption.responseType= 'blob'
  // }

  // axios(axiosOption).
  axios.get1('/report-info/mouthExcelDownload?startTime='+monthExcelForm.value.startTime+'&endTime='+monthExcelForm.value.endTime,  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
    },
    data:monthExcelForm.value,

    responseType: 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
  }).
    then(function (response) {
    const blob = new Blob([response.data],{type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8"});
    const fileName = '转诊单详情.xls';
    const linkNode = document.createElement('a');

    linkNode.download = fileName; //a标签的download属性规定下载文件的名称
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click();  //模拟在按钮上的一次鼠标单击

    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);

  }).catch(function (error) {
    console.log(error);
  });
}



initProductList();

const dialogVisible=ref(false)

const imageDialogVisible=ref(false)

const swiperDialogVisible=ref(false)

const productSwiperImageDialogVisible=ref(false)


const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initProductList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initProductList();
}


const handleDialogValue = (row) => {
  if(row){
    dialogValue.value=JSON.parse(JSON.stringify(row));
    console.log("abc:"+dialogValue.value.reportId)
    dialogTitle.value="修改转诊单"
  }else{
    dialogValue.value={
      productIntroImgs:'',
      productParaImgs:'',
      type:{
        id:""
      }
    }
    dialogTitle.value="添加转诊单"
  }
  dialogVisible.value=true;
}

const handleDelete = (id) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {
      console.log("id="+id)
      let res=await axios.del("report-info/removeById?reportId="+id);
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initProductList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.message,
        });
      }

    })
    .catch(() => {

    })
}


const typeFormatter = (row) => {
  return row.type.name
}



const hotChangeHandle = async (row) => {
  console.log(row);
  console.log("val="+row.id+","+row.hot);
  let res=await axios.get("admin/product/updateHot/"+row.id+"/state/"+row.hot);
  if(res.data.code==0){
    ElMessage({
      type: 'success',
      message: '执行成功！',
    });
  }else{
    ElMessage({
      type: 'error',
      message: res.data.msg,
    });
    initProductList();
  }
}


const hotSwiperChangeHandle = async (row) => {
  console.log("val="+row.id+","+row.hot);
  let res=await axios.get("admin/product/updateSwiper/"+row.id+"/state/"+row.swiper);
  if(res.data.code==0){
    ElMessage({
      type: 'success',
      message: '执行成功！',
    });
  }else{
    ElMessage({
      type: 'error',
      message: res.data.msg,
    });
    initProductList();
  }
}

const handleChangeImage = (row) => {
  id.value=row.id;
  imageDialogVisible.value=true;
}

const handleChangeSwiper = (row) => {
  id.value=row.id;
  swiperSort.value=row.swiperSort;
  swiperDialogVisible.value=true;
}

const handleChangeProductSwiperImage = (row) => {
  id.value=row.id;
  productSwiperImageDialogVisible.value=true;
}


</script>

<style lang="scss" scoped>

.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}


</style>
